import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Form, Input } from 'antd';
import Modal from '../../Modal';
import { saveRowCategory } from '../savedRows/api';
import { useToast } from '../../../redux/action';

const SavedRowsCategoryModal = ({
  rowCategoryName,
  setRowCategoryName,
  setRowCategoryId,
  setRowCategoryModalVisibility,
  setDynamicRowsConfiguration,
}) => {
  const updateRowName = event => setRowCategoryName(event.target.value);
  const { errorToast } = useToast();

  const handleSave = async () => {
    try {
      if (rowCategoryName) {
        setRowCategoryName(rowCategoryName);
        setRowCategoryModalVisibility(false);
        const categoryId = uuidv4();
        setRowCategoryId(categoryId);
        await saveRowCategory({ name: rowCategoryName, id: categoryId });

        setDynamicRowsConfiguration(prevConfig => ({
          ...prevConfig,
          externalContentURLs: [
            ...prevConfig.externalContentURLs,
            {
              name: rowCategoryName,
              id: categoryId,
              handle: categoryId,
              isLocal: true,
              behaviors: { canEdit: true, canDelete: true },
            },
          ],
        }));
      }
    } catch (e) {
      console.error('Error in handleSave:', e);
      errorToast('Encountered an error while saving a row category.');
    }
  };

  const handleCancel = () => {
    setRowCategoryModalVisibility(false);
  };

  return (
    <Modal
      className="templatemodal save-as-modal"
      title="Save as a New Category Row"
      onClose={handleCancel}
      primaryButton={{
        label: 'Save',
        onClick: handleSave,
      }}
    >
      <Form>
        <Form.Item className="m0">
          <Input
            type="text"
            placeholder="Row Category Name"
            value={rowCategoryName}
            onChange={updateRowName}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SavedRowsCategoryModal;
