import { Form, Input, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Modal from '../../Modal';
import SavedRowsCategoryModal from './SavedRowsCategoryModal';
import { Container, AddCategoryBtn, CloseBtn } from './SavedRowsModal.styled';

const SavedRowsModal = ({
  resolve,
  args,
  setDynamicRowsConfiguration,
  dynamicRowsConfiguration,
}) => {
  const { row, onHide, isNew } = args;
  useEffect(() => {
    if (isNew) {
      row.metadata = {};
    }
  }, []);

  const [rowName, setRowName] = useState('');
  const [rowCategoryName, setRowCategoryName] = useState('');
  const [rowCategoryId, setRowCategoryId] = useState('');
  const [isRowCategoryModalVisible, setRowCategoryModalVisibility] =
    useState(false);
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [categoryNames, setCategoryNames] = useState(
    dynamicRowsConfiguration.externalContentURLs || []
  );

  const updateRowName = event => setRowName(event.target.value);

  const isCategoryIdValid = () => {
    if (
      !row?.metadata?.categoryId ||
      !dynamicRowsConfiguration?.externalContentURLs
    ) {
      return false;
    }

    return dynamicRowsConfiguration.externalContentURLs.some(
      category => category.value === row.metadata.categoryId
    );
  };

  useEffect(() => {
    setCategoryNames(dynamicRowsConfiguration.externalContentURLs);
    const isValid = isCategoryIdValid();
    if (isValid) {
      setRowName(row.metadata.name);
      setRowCategoryId(row?.metadata?.categoryId);
      setRowCategoryName(row?.metadata?.category);
    }
  }, [dynamicRowsConfiguration]);

  const handleSave = () => {
    if (rowName && rowCategoryName && rowCategoryId) {
      resolve({
        name: rowName,
        category: rowCategoryName,
        categoryId: rowCategoryId,
      });
      onHide();
    }
  };

  const handleCancel = () => {
    resolve('Save cancelled');
    onHide();
  };

  const renderDropdown = menu => {
    return (
      <>
        {menu}
        <Container>
          <AddCategoryBtn
            onClick={() => {
              setRowCategoryModalVisibility(true);
              setRowCategoryName('');
            }}
          >
            <AddIcon fontSize="small" />
            Create New Row Category
          </AddCategoryBtn>
          <CloseBtn onClick={() => setDropdownVisibility(false)}>Done</CloseBtn>
        </Container>
      </>
    );
  };

  const handleCategoryChange = value => {
    try {
      const { name, id } = JSON.parse(value);
      setRowCategoryName(name);
      setRowCategoryId(id);
    } catch (error) {
      console.error('Error parsing category value:', error);
    }
  };

  return isRowCategoryModalVisible ? (
    <SavedRowsCategoryModal
      rowCategoryName={rowCategoryName}
      setRowCategoryName={setRowCategoryName}
      setRowCategoryId={setRowCategoryId}
      setRowCategoryModalVisibility={setRowCategoryModalVisibility}
      setDynamicRowsConfiguration={setDynamicRowsConfiguration}
    />
  ) : (
    <Modal
      className="templatemodal save-as-modal"
      title="Save as a New Row"
      onClose={handleCancel}
      primaryButton={{
        label: 'Save',
        onClick: handleSave,
        isDisabled: !rowName || !rowCategoryName || !rowCategoryId,
      }}
    >
      <Form>
        <Form.Item className="mb15">
          <Input
            type="text"
            placeholder="Row Name"
            value={rowName}
            onChange={updateRowName}
          />
        </Form.Item>
        <Form.Item className="mb15">
          <Select
            value={
              rowCategoryName
                ? JSON.stringify({ name: rowCategoryName, id: rowCategoryId })
                : undefined
            }
            onChange={handleCategoryChange}
            placeholder="- Select Row Category -"
            showSearch
            showArrow
            optionFilterProp="children"
            open={isDropdownVisible}
            onDropdownVisibleChange={() =>
              setDropdownVisibility(!isDropdownVisible)
            }
            searchValue={searchValue}
            onSearch={setSearchValue}
            notFoundContent={<span>No available options</span>}
            dropdownRender={renderDropdown}
          >
            {categoryNames.map(category => (
              <Select.Option
                key={category.handle}
                value={JSON.stringify({
                  name: category.name,
                  id: category.handle,
                })}
              >
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SavedRowsModal;
