import http from '../../../utils/andromeda-api';
import store from '../../../redux/store';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';

const getOrganizationId = () => {
  return selectedOrganizationSelector(store.getState()).id;
};

export const getRows = async () => {
  try {
    const organizationId = getOrganizationId();
    const {
      data: { result },
    } = await http.get(`/savedRows/${organizationId}`);
    return result;
  } catch (error) {
    console.error('Error in getRows:', error);
    throw error;
  }
};

export const getRowCategories = async () => {
  try {
    const organizationId = getOrganizationId();
    const {
      data: { result },
    } = await http.get(`/savedRows/categories/${organizationId}`);

    return {
      emptyRows: true,
      defaultRows: true,
      externalContentURLs: result.map(category => ({
        name: category.name,
        value: category.id,
        handle: category.id,
        isLocal: true,
        behaviors: { canEdit: true, canDelete: true },
      })),
    };
  } catch (error) {
    console.error('Error in getRowCategories:', error);
    throw error;
  }
};

export const saveRow = async ({ name, content, categoryId, id }) => {
  try {
    if (!name || !content || !categoryId)
      throw new Error('name, content and categoryId are required fields');
    const organizationId = getOrganizationId();

    const { data } = await http.post('/savedRows', {
      organizationId,
      name,
      content,
      categoryId,
      id,
    });

    return data;
  } catch (error) {
    console.error('Error in saveRow:', error);
    throw error;
  }
};

export const saveRowCategory = async ({ name, id }) => {
  try {
    if (!name || !id) throw new Error('name and id are required fields');
    const organizationId = getOrganizationId();

    const { data } = await http.post('/savedRows/category', {
      organizationId,
      name,
      id,
    });

    return data;
  } catch (error) {
    console.error('Error in saveRowCategory:', error);
    throw error;
  }
};

export const editRow = async ({ rowId, content, name, categoryId }) => {
  try {
    if (!rowId) throw new Error('rowId is required');
    const { data } = await http.put(`/savedRows/${rowId}`, {
      content,
      name,
      categoryId,
    });

    return data;
  } catch (error) {
    console.error('Error in editRow:', error);
    throw error;
  }
};

export const deleteRow = async rowId => {
  try {
    if (!rowId) throw new Error('rowId is required');

    const { data } = await http.delete(`/savedRows/${rowId}`);

    return data;
  } catch (error) {
    console.error('Error in deleteRow:', error);
    throw error;
  }
};
