import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
`;

export const AddCategoryBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #1890ff;
  transition: color 250ms;
  &hover: {
    color: #40a9ff;
  }
`;

export const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #1890ff;
  transition: color 250ms;
  &hover: {
    color: #40a9ff;
  }
`;
